@import "../../variables.modules";

.text {
  margin-bottom: 1.5rem!important;
  font-family: Open Sans,serif;
  font-weight: 400;
  font-size: 1.15rem;
  line-height: 1.22;
  color: #868e96;
}

.copy {
  margin-bottom: 1.1rem!important;
  font-family: Open Sans,serif;
  font-weight: 400;
  font-size: 1.15rem;
  line-height: 1.22;
  color: #868e96;
}

.someone {
  text-transform: uppercase;
  font-weight: 500;
  font-family: Saira Extra Condensed,serif;
  font-size: 1.35rem;
  color: #868e96;
}


.h1 {
  font-family: Open Sans,serif;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 1.5rem!important;
}
.subtitle {
  font-family: Saira Extra Condensed,serif;
  font-weight: 700;
  font-size: 1.95rem;
  text-transform: uppercase;
  color: #343a40;
}
.date{
  color: #bd5d38!important;
  font-size: 1.15rem;
  font-family: Open Sans,serif;
} 
.university {
  text-transform: uppercase;
  font-weight: 500;
  font-family: Saira Extra Condensed,serif;
  font-size: 1.55rem;
  margin-bottom: 1.5rem!important;
}
